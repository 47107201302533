import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO site={data.site} title="Recommended by Krzysztof Witczak" />

        <h1 className="about-header">My Recommendations</h1>
        <div className="about-content">
          <div className="about-text">
            <h2>Technical influencers worth observing</h2>
            <p>
              IMO these people are impacting engineering world a lot, they
              create high quality content - its worth watching their websites,
              talks, social media, and more.
            </p>
            <ul>
              <li>
                <a href="https://blog.pragmaticengineer.com/author/gergely/">
                  Gergely Orosz
                </a>{" "}
                - creator of Pragmatic Engineer newsletter, also active on
                social media.
              </li>
              <li>
                <a hrew="https://www.linkedin.com/in/will-larson-a44b543/">
                  Will Larson
                </a>{" "}
                - fantastic C-level technical advisor.
              </li>
              <li>
                <a href="https://www.rubick.com/bottleneck-leaders/?utm_source=pocket_shared">
                  Jade Rubick
                </a>{" "}
                - fantastic, advanced recommendations on technical leadership.
              </li>
              <li>
                <a href="https://www.linkedin.com/in/patkua/?originalSubdomain=de">
                  Pat Kua
                </a>{" "}
                - technical leadership.
              </li>
              <li>
                <a hrew="https://www.linkedin.com/in/jstanier/?originalSubdomain=uk">
                  James Stanier
                </a>{" "}
                - engineering management, remote working.
              </li>
              <li>
                <a href="https://www.allthingsdistributed.com/">
                  Werner Vogels
                </a>{" "}
                - personal blog of Amazon CTO.
              </li>
              <li>
                <a href="https://www.subbu.org/articles/2023/shaping-your-authenticity/">
                  Subbu Allamaraju
                </a>{" "}
                - creator of fantastic posts about leadership and understanding
                yourself better.
              </li>
              <li>
                <a href="https://kellblog.com/2015/05/13/career-advice-simplifiers-go-far-complexifiers-get-stuck/">
                  Dave Kellogg
                </a>{" "}
                - fantastic C-level technical advisor.
              </li>
              <li>
                <a href="https://zverok.space/blog/2024-07-26-range-evolution.html">
                  Victor Shepelev (zverok)
                </a>{" "}
                - Ruby guru.
              </li>
              <li>
                <a href="https://blog.alexewerlof.com/">Alex Ewerlöf</a> -
                creates great content for staff-level engineers, especially
                around SRE and Devops.
              </li>
              <li>
                <a href="https://architectelevator.com/">Gregor Hohpe</a> -
                delivers top quality content about software architecture.
              </li>
              <li>
                <a href="https://www.ufried.com/">Uwe Friedrichsen</a> - another
                author of great articles about distributed systems and approach
                to architecture.
              </li>
              <li>
                <a href="https://www.robinwieruch.de/">Robin Wieruch</a> - one
                of the most knowledgeable frontend engineers I have ever seen.
              </li>
              <li>
                <a href="https://martinfowler.com/">Martin Fowler</a> - another
                legend who keeps posting interesting posts about architecture
                and how organizations struggle to build good software.
              </li>
            </ul>
            <h2>Top resources - best quality</h2>
            <ul>
              <li>
                <a href="https://www.ctomorning.coffee/">CTO Morning Coffee</a>{" "}
                - it's a 🇵🇱 podcast with unusual form. Three highly experienced
                technical leaders - Tomasz Onyszko, Wojciech Ptak and Sebastian
                Gębski discuss recent IT revelations at least once per month.
                However on top of that they also create mastermind-style
                meetings which anyone can join to discuss particial topic.
              </li>
              <li>
                <a href="https://blog.pragmaticengineer.com/newsletter/">
                  The Pragmatic Engineer
                </a>{" "}
                - it exists in both free and paid version, and I can highly
                recommend the latter. At this point of time, I think it may be
                the highest quality newsletter for IT professionals on the web.
                What's important is that <b>Gergely Orosz</b> is a creator of
                the unique content, not merly aggregator of interesting links.
              </li>
              <li>
                <a href="https://actions.tldrnewsletter.com/web-version?">
                  TLDR
                </a>{" "}
                - a new newsletter mostly focused about news in the industry,
                but good programming articles show up as well. It's really good
                in a sense that in every issue I find something unique and
                interesting that cannot be find elsewhere.
              </li>
              <li>
                <a hrew="https://www.pointer.io/">Pointer</a> - great newsletter
                combining great articles (for my taste) from both technical and
                leadership world.
              </li>
              <li>
                <a href="https://leaddev.com/newsletter">LeadDev</a> - this one
                is not an aggregator, but creator of the very high quality,
                unique content on the web. It covers famous people from the
                industry, like mentioned Pat Kua, but also James Stanier,
                Camille Fournier and others. They cover many topics but it's
                directed especially towards Technical Leaders and Engineering
                Managers. In some cases CTO's will find useful information here.
              </li>
              <li>
                <a href="https://theengineeringmanager.substack.com/">
                  The Engineering Manager
                </a>{" "}
                - blog created by <b>James Stanier</b>, who shares one of the
                most hands-on advices for Engineering Managers. His websites has
                a huge base of very high quality articles.
              </li>
              <li>
                <a href="https://lethain.com/newsletter/">
                  Irrational Exuberance
                </a>{" "}
                - newsletter of famous <b>Will Larson</b>, magnificent resources
                for executives, CTO's, directors who lead technical
                organisations.
              </li>
              <li>
                <a href="https://hbr.org/">Harvard Business Review</a> - most of
                the content requires subscription, but sometimes older pieces
                are accessible to public and usually they are simply fantastic.
                They also produce a lot of books with a famous{" "}
                <a href="https://www.waterstones.com/product/hbr-emotional-intelligence-ultimate-boxed-set-14-books-hbr-emotional-intelligence-series/harvard-business-review/daniel-goleman/9781633699410?awc=3787_1677403504_35f85bb4ad1a3ad2e6df3fbc7ef07e0f&utm_source=323889&utm_medium=affiliate&utm_campaign=Kiesproduct+%22Google+Shopping+traffic%22">
                  series about Empathy{" "}
                </a>
                covering 14 books and 2320 pages... as you see, they like to go
                deep.
              </li>
              <li>
                <a href="https://review.firstround.com/">First Round Review</a>{" "}
                - quality of content equal to HBR and as actionable as topics
                from LeadDev. Simply fantastic. They also group content for
                series dedicated to specific audience, like first-time line
                managers or managers who start managing other managers.
              </li>
              <li>
                <a href="https://no-kill-switch.ghost.io/">No kill switch</a>
                Technical/leadership blog of Sebastian Gębski. I admire a short
                form of the blog posts and the form of writing - it's easy to
                follow and apply in practice.
              </li>
              <li>
                <a href="https://www.thoughtworks.com/radar">
                  Thoughtworks Tech Radar
                </a>{" "}
                - fantastic strategic tech discovery and exploration resource.
                It's great to review it every quarter to know if there are any
                interesting trends in the industry, either worth adapting or
                ditching. There are many haters of this radar, but it's
                important to treat this as tech discovery tool instead of a
                blind recommendation.
              </li>
            </ul>
            <h2>Good resources - worth considering</h2>
            <ul>
              <li>
                <a href="https://levelup.patkua.com/">Level Up</a> - awesome,
                free aggregator of the best, recent news in IT created by{" "}
                <b>Pat Kua</b>. I highly agree with him im terms of what bits of
                articles are high quality ones, so I end up reading most of what
                he recommends. It's mostly directed towards IT leadership crew,
                on a Technical Leader level.
              </li>
              <li>
                <a href="https://softwareleadweekly.com/fwd">
                  Software Lead Weekly
                </a>{" "}
                - a similar, aggregcontent to what Level Up brings, many times
                same articles repeat. However, I often find links from{" "}
                <b>Oren Ellenbogen</b> even more useful for Engineering
                Managers, especially due to Peopleware section.
              </li>
              <li>
                <a href="https://techleaddigest.net/">Tech Lead digest</a> - a
                similar content to Level Up, and for the same audience. If
                you'll subscribe to both, majority of links will overlap. The
                advangate of this one is hte fact that it is shorter.
              </li>
              <li>
                <a href="https://effectiviology.com/">Effectiviology</a> -
                amazing blog about human biases, psychology and behaviours.
              </li>
              <li>
                <a href="https://programmingdigest.net/">Programming digest</a>{" "}
                - puerly technical newsletter, focused on new content from the
                web. Very solid, short and useful for any language.
              </li>
              <li>
                <a href="https://randsinrepose.com/welcome-to-the-rands-newsletter/">
                  Rands newsletter
                </a>{" "}
                - it covers all aspects of working with software engineers,
                leading them, teamwork. I'm not certain about the newsletter,
                but content from the past is simply amazing.
              </li>
              <li>
                <a href="https://swizec.com/testimonials/">
                  Senior Engineer Mindset
                </a>{" "}
                - a newsletter from <b>Swizec Teller</b>, directed mostly
                towards senior engineers and IC's. Has a lot of great career
                tips.
              </li>
              <li>
                <a href="https://thoughtbot.com/newsletter">
                  Giant Robots Weekly
                </a>{" "}
                - a newsletter from Thoughtbot, famous company using Ruby on
                Rails. Has a lot of powerful insights and clean code, useful for
                senior engineers.
              </li>
              <li>
                <a href="https://ctocraft.com/community/?ref=tech-manager-weekly">
                  CTO Craft
                </a>{" "}
                - a similar content to Software Lead Weekly, altough not as
                good. However, if you're an engineering manager, it may be a
                really good resource for you.
              </li>
              <li>
                <a href="https://rubyweekly.com/">Ruby Weekly</a> - official
                Ruby newsletter with a long, long history. If something
                important or big enough happens in the Ruby community, most
                commonly it will appear on this list. However, there are weekly
                issues where I won't find anything new or interesting, or where
                I know a good article was deployed on reddit or hacker news, but
                unfortunately it didn't win a place on a list.
              </li>
              <li>
                <a href="https://www.gartner.com/en">Gartner</a> - one of my
                more recent resources. Their reports and infographics are
                outstanding, covering topics which are unique and dificult to
                find in any other website.
              </li>
              <li>
                <a href="https://increment.com/">Increment</a> - basically a
                high quality book or digital magazine about creating software,
                made as a website, split into multiple articles.{" "}
              </li>
              <li>
                <a href="https://testing.googleblog.com/search/label/Code%20Health?by-date=false&max-results=20&start=16&updated-max=2017-08-14T11%3A45%3A00-07%3A00&utm_source=pocket_reader">
                  Google Testing Blog
                </a>{" "}
                - small pills of knowledge here and there.{" "}
              </li>
              <li>
                <a href="https://untools.co/inversion">Untools</a> - a bunch of
                different decision-making, system-thinking, problem solving
                tools to use.{" "}
              </li>
              <li>
                <a href="https://developer.mozilla.org/en-US/">MDN Web docs</a>{" "}
                - it's a shame it's not more commonly used by developers.
                Touches every aspect of web development and it's constantly up
                to date.
              </li>
              <li>
                <a href="https://testing-library.com/">TestingLibrary</a> - a
                lot of knowledge about testing in a single place.
              </li>
              <li>
                <a href="https://learning.oreilly.com/search/topics/software-architecture/?type=course&rows=10">
                  O'Reilly Online Library
                </a>{" "}
                - I use it every couple days to look for solutions to my
                problems - in books, courses, articles... worth every penny.
              </li>
              <li>
                <a href="https://javascript.info/logical-operators">
                  JavaScript Info
                </a>{" "}
                - Just a great, online book about JS.
              </li>
              <li>
                <a href="https://www.promptingguide.ai/techniques/zeroshot">
                  PromptingGuide.ai
                </a>{" "}
                - Very fresh guide on AI prompting.
              </li>
              <li>
                <a href="https://cpu.land/the-basics">CPU.land</a> - Great
                project on learning the fundamentals of CPU inner workings.
              </li>
              <li>
                <a href="https://codingchallenges.fyi/challenges/challenge-wc/#the-challenge---building-wc">
                  Coding Challenges
                </a>{" "}
                - great ideas for project-based Katas.
              </li>
              <li>
                <a href="https://github.com/codecrafters-io/build-your-own-x">
                  Build your own X
                </a>{" "}
                - step-by-step guides on build your own Docker, OS, Git and many
                other famous tech.
              </li>
              <li>
                <a href="https://github.com/practical-tutorials/project-based-learning#elixir">
                  Project based learning
                </a>{" "}
                - similar concept as above
              </li>
              <li>
                <a href="https://www.destroyallsoftware.com/screencasts">
                  Destroy All Software
                </a>{" "}
                - touches many CS topics worth knowing.
              </li>
              <li>
                <a href="http://codekata.com/">CodeKata</a> - train over a
                specific idea with your favorite technology.
              </li>
              <li>
                <a href="https://www.codewars.com/">CodeWars</a> - coding
                riddles.
              </li>
              <li>
                <a href="https://www.algoexpert.io">AlgoExpert (and others)</a>{" "}
                - tutorial on algorithms and many other fields lately, very
                comprehensive.
              </li>
              <li>
                <a href="https://www.driftingruby.com/episodes">
                  Drifting Ruby
                </a>{" "}
                - short videos about Rails.
              </li>
              <li>
                <a href="https://gorails.com/">GoRails</a> - short videos about
                Rails.
              </li>
              <li>
                <a href="https://www.youtube.com/watch?v=KUYCksB7e0g">
                  IBM Technology
                </a>{" "}
                - short, great videos on various topics. Love them!{" "}
              </li>
              <li>
                <a href="https://www.youtube.com/watch?v=IqHaGd9J42s">
                  ThePrimeTime
                </a>{" "}
                - pure craziness but I like the energy and primeagen is a really
                smart and skillful engineer.
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        metaTitle
      }
    }
  }
`
